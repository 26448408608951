<template>
    <div>
        <Navigation />
        <img :src="imgUrl" alt="" class="banner">
        <!-- 产品介绍 -->
        <div class="product_box">
            <img src="@/assets/product/productTitle1.png" alt="" class="title">
            <ul class="list flex">
                <li class="list_li" v-for="(item, index) in list" :key="index" @click="goView(index)">
                    <img :src="item.imgUrl" alt="">
                    <p class="list_li_title">{{ item.title }}</p>
                    <p class="op">
                        <span>{{ item.title }}</span>
                        <span>{{ item.op_p }}</span>
                        <span>{{ item.op_p2 }}</span>
                        <span>{{ item.op_p3 }}</span>
                    </p>
                </li>
            </ul>
        </div>

        <div class="partner">
            <div class="ct_box">
                <img class="title" src="../assets/product/productTitle2.png" alt="">
                <div class="form_box flex">
                    <el-select v-model="form.type" placeholder="请选择合作产品">
                        <el-option v-for="(item, index) in options" :label="item.title" :key="index" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-input class="text" v-model="form.contacts" placeholder="输入联系人"></el-input>
                    <el-input class="text" v-model="form.mobile" placeholder="输入联系电话"></el-input>
                </div>
                <el-input type="textarea" resize="none" v-model="form.des" maxlength="100"
                    :show-word-limit="true"></el-input>
                <el-button type="primary" @click="sub">提交</el-button>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import {goAi} from '@/util/goAi'
import {mapState} from 'vuex'
export default {
    name: '',
    computed:{
        ...mapState(['loginShow'])
    },
    created() { },
    mounted() {
        this.BannerImgFn()
    },
    data() {
        return {
            list: [
                {
                    imgUrl: require('../assets/product/1.png'),
                    title: '媒体发布',
                    op_p: '一站式媒体投放，助力企业品牌建设;',
                    op_p2: '招商、引流、宣传、背书，快速提高企业（品牌）曝光率及美誉度！让宣传更省钱，让效果更直观。'
                },
                {
                    imgUrl: require('../assets/product/5.png'),
                    title: '可信网站',
                    op_p: '帮助网民核验网站身份，识别网站真假；权威机构评定验证帮助网站亮明真实身份；维护企业网站形象防范钓鱼网站；多平台信誉展示让网站流量变销量。',
                    op_p2: '',
                },
                {
                    imgUrl: require('../assets/product/3.png'),
                    title: '视频制作',
                    op_p: '高效拍摄：为您呈现完美的镜头。',
                    op_p2: '专业剪辑：对视频进行精心的剪辑和包装，让作品更具吸引力。个性化定制：从题材选择到拍摄风格，我们都将竭诚为您服务。'
                },
                {
                    imgUrl: require('../assets/product/4.png'),
                    title: '智能写作',
                    op_p: '智能写作，颠覆想象！',
                    op_p2: '您是否曾为写作困扰，苦思不得其解？是否曾因时间紧迫，难以完成大量文字工作？',
                    op_p3: '不断学习进化的AI写作，为您带来全新的写作体验。',
                },
                {
                    imgUrl: require('../assets/product/2.png'),
                    title: '技术开发',
                    op_p: '用我们的技术力量，打造您的数字城堡！',
                    op_p2: '我们的专业团队将为您的网站、app和小程序提供全面的技术支持和研发更新服务。',
                },
                {
                    imgUrl: require('../assets/product/6.jpg'),
                    title: '享视界',
                    op_p: '享视界，想您所想!',
                    op_p2: '您是否曾为制作视频素材以及书写专业文书等工作无从下手?不断学习进化的AI办公，为您带来高效的体验，满足您的多方需求。',
                },
            ],
            // 选项
            options: [{
                id: 1,
                title: '媒体发布'
            }, {
                id: 2,
                title: '技术开发'
            }, {
                id: 3,
                title: '视频制作',
            },
            {
                id: 4,
                title: '智能写作'
            },
            {
                id: 5,
                title: '可信认证'
            }
            ],
            form: {
                type: '', //类型
                contacts: '', //联系人名字
                mobile: '', //联系电话
                des: '', //留言
            },
            imgUrl: '',
            fullscreenLoading: false,
        }
    },
    methods: {
        BannerImgFn() {
            this.curlGet('/api/advert/detail', {
                mark: 'product_banner_01'
            }).then(res => {
                if (res.data.code) {
                    this.imgUrl = res.data.data.image
                }
            })
        },
        sub() {
            this.fullscreenLoading = true
            this.curlPost('/api/cooperate/save', this.form).then(res => {
                console.log(res);
                this.fullscreenLoading = false
                if (res.data.code) {
                    this.form = {
                        type: '', //类型
                        contacts: '', //联系人名字
                        mobile: '', //联系电话
                        des: '', //留言
                    }
                    setTimeout(() => {
                        this.$message({
                            offset: 80,
                            type: 'success',
                            message: '上传成功',
                        })
                    }, 100)
                } else {
                    setTimeout(() => {
                        this.$message({
                            offset: 80,
                            type: 'warning',
                            message: res.data.msg,

                        })
                    }, 100)
                }

            })
        },
        goView(index) {
            switch (index) {
                case 0:
                    this.$router.push('/media/News')
                    break;
                case 1:
                    this.$router.push('/credible')
                    break;
                case 3:
                    this.$router.push('/word')
                    break;
                case 4:
                    this.$router.push('/technical')
                    break;
                case 5:

                    this.goAiNav()

                default:
                    break;
            }
        },
        // 去功能选择页面
        async goAiNav() {
            const aiStatus = await goAi()
            console.log('aiStatus', aiStatus);
            if (aiStatus == 'ok') {
                this.$router.push('/aiNav')
            } else {
                return this.$store.state.loginShow = true
            }
        },
    },
}
</script>

<style lang=scss scoped>
@import '../scss/Product'
</style>